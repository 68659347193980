.NavigationLayout {
  height: 100vh;
  overflow: 'auto';
  min-width: 600px;
  padding-bottom: 5vh;

  .SiderHeader {
    padding-top: 28px;
    padding-left: 26px;
    transition: opacity 0.5s;
  }

  :global(.ant-layout-sider-collapsed) {
    .SiderHeader {
      opacity: 0;
    }
  }
}

@primary-color: #1871ed;@error-color: #D93616;@success-color: #63BE09;@warning-color: #FFBA00;@table-header-bg: transparent;@card-radius: 8px;@card-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);@layout-header-background: #FFFFFF;@layout-body-background: #F2F2F2;@page-header-heading-title: 24px;@page-header-padding-vertical: 24px;@page-header-border-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);@btn-border-radius-base: 6px;